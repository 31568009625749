import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import { Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, Alert } from 'reactstrap';

import NoteList from '../../notes/components/NoteList';

import './App.scss';
import { toggle, dismissAlert, AppAction } from '../actions';
import { AlertState } from '../state';

type Props = {
  navbarIsOpen: boolean,
  alert: AlertState,

  toggle: () => void,
  dismissAlert: () => void,
};

class App extends Component<Props> {
  render = () => (
    <Router>
      <Navbar color="light" light expand="md">
        <NavbarBrand>Flote</NavbarBrand>
        <NavbarToggler onClick={() => this.props.toggle()} />
        <Collapse isOpen={this.props.navbarIsOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/">Notes</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      <Alert
        isOpen={this.props.alert.visible}
        color={this.props.alert.type}
        toggle={this.props.dismissAlert}>
        {this.props.alert.content}
      </Alert>

      <Container>
        <Route exact path="/" component={NoteList} />
      </Container>
    </Router>
  );
}

const mapStateToProps = (state: any): Props => state.app;

const mapDispatchToProps = (dispatch: Dispatch<AppAction>) => ({
  toggle: () => dispatch(toggle()),
  dismissAlert: () => dispatch(dismissAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
