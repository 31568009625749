
import { Note } from './models';

const notes: Note[] = [
  {
    id: "11e82526-ebde-430a-8bdc-9b7e48519df7",
    content: "All Logic Apps within a region use the same inbound and outbound IP addresses. There can be a security issue where services with IP restrictions can be called by other Logic Apps in the same region",
    date: new Date(),
    topic: "azure"
  },
  {
    id: "662f5d8a-4cac-4d70-9d25-d609553bcdd7",
    content: "Linux based Function apps must currently be published using the Azure cli and Function App cli. Create the function app first, then in the project run `func azure functionapp publish <function-app-name>`",
    date: new Date(),
    topic: "azure"
  },
  {
    id: "e8946bf6-7141-4ed5-85c3-c4f1f9c9f9a0",
    content: "\"Any validation needs a context to give some information about what is being validated. This can include various information such as the object to be validated, some properties, the name to display in the error message, etc.\" [How to manually validate a model with attributes?](https://stackoverflow.com/questions/17138749/how-to-manually-validate-a-model-with-attributes)",
    date: new Date(),
    topic: "csharp"
  }
];

export interface NoteState {
  // Notes being listed
  notes: Note[];

  // Optional Note being edited
  note?: Note;
}

export const initialState = () => <NoteState>{
  notes: notes,
};
