import { combineReducers } from 'redux';

import appReducer from './app/reducer';
import noteReducer from './notes/reducer';



export default combineReducers({
  app: appReducer,
  notes: noteReducer,
});
