
import React, { Component, Dispatch } from 'react';
import { ButtonGroup, Button, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import NoteItem from './NoteItem';

import { NoteAction, openNoteEdit, cancelNoteEdit, saveNoteEdit, changeNoteContent, NoteThunk } from '../actions';
import { Note } from '../models';
import { NoteState } from '../state';

type Actions = {
  noop: () => void,
  openNoteEdit: (note: Note) => void,
  cancelNoteEdit: () => void,
  saveNoteEdit: (note: Note) => any,
  changeNoteContent: (content: string) => void,
}

type Props = NoteState & Actions;

class NoteList extends Component<Props> {
  render = () => (
    <Row>
      <Col>
        <Row>
          <Col>
            <ButtonGroup className="float-right mb-3 mt-3">
              <Button outline={true}>Sort</Button>
              <Button outline={true}>Filter</Button>
              <Button outline={true} color="primary">Add</Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.notes.map((note, _index) => (
              <NoteItem
                key={note.id}
                note={this.props.note && this.props.note.id === note.id ? this.props.note : note}
                editing={this.props.note && this.props.note.id === note.id}
                onEditOpen={this.props.openNoteEdit}
                onEditCancel={this.props.cancelNoteEdit}
                onEditSave={this.props.saveNoteEdit}
                onContentChange={this.props.changeNoteContent} />
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state: any): Props => state.notes;

const mapDispatchToProps = (dispatch: Dispatch<NoteAction | NoteThunk>): Actions => ({
  noop: () => { },
  openNoteEdit: note => dispatch(openNoteEdit(note)),
  cancelNoteEdit: () => dispatch(cancelNoteEdit()),
  saveNoteEdit: note => dispatch(saveNoteEdit(note)),
  changeNoteContent: content => dispatch(changeNoteContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteList);
