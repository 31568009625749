
import {
  NoteAction,
  NoteActionType,
  ListNotes,
  OpenNoteEdit,
  SaveNoteEdit,
  ChangeNoteContent
} from './actions';
import { NoteState, initialState } from './state';

export default (state: NoteState = initialState(), action: NoteAction): NoteState => {
  switch (action.type) {
    case NoteActionType.ListNotes:
      return {
        ...state,
        notes: (<ListNotes>action).payload,
      }
    case NoteActionType.OpenNoteEdit:
      return {
        ...state,
        note: (<OpenNoteEdit>action).note,
      }
    case NoteActionType.CancelNoteEdit:
      return {
        ...state,
        note: undefined,
      }
    case NoteActionType.SaveNoteEdit: {
      let editedNote = (<SaveNoteEdit>action).note;
      let notes = state.notes.map(note =>  note.id === editedNote.id ? editedNote : note);

      return {
        ...state,
        notes,
      }
    }
    case NoteActionType.ChangeNoteContent:
      return {
        ...state,
        note: state.note && {
          ...state.note,
          content: (<ChangeNoteContent>action).content,
        }
      }
    default:
      return state
  }
}

