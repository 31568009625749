
import { AppAction, AppActionType, ShowAlert } from './actions';
import { AppState, initialState } from './state';

export default (state = initialState(), action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.ToggleNavbar:
      return {
        ...state,
        navbarIsOpen: !state.navbarIsOpen,
      };
    case AppActionType.ShowAlert: {
      let { content, alertType } = (<ShowAlert>action);
      return {
        ...state,
        alert: {
          visible: true,
          content,
          type: alertType,
        }
      }
    }
    case AppActionType.DismissAlert:
      return {
        ...state,
        alert: {
          ...state.alert,
          visible: false,
        }
      }
    default:
      return state;
  }
}
