
// TODO: We're going to need a toast/snackbar, so this would move into a component
export enum AlertType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
}

export interface AlertState {
  visible: boolean,
  content: string,
  type: AlertType,
}

export interface AppState {
  // Is navbar menu collapsed?
  navbarIsOpen: boolean;
  alert: AlertState;
}

export const initialState = () => <AppState>{
  navbarIsOpen: false,
  alert: {
    visible: false,
    content: '',
    type: AlertType.Info,
  }
};
