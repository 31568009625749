
import { Action } from 'redux';
import { AlertType } from './state';

export enum AppActionType {
  ToggleNavbar = 'APP_TOGGLE_NAVBAR',
  ShowAlert = 'APP_SHOW_ALERT',
  DismissAlert = 'APP_DISMISS_ALERT',
}

export type AppAction = ToggleNavbar
  | ShowAlert
  | DismissAlert;

export type ToggleNavbar = Action<AppActionType>;
export const toggle = (): ToggleNavbar => ({
  type: AppActionType.ToggleNavbar,
});

export type ShowAlert = Action<AppActionType> & {
  content: string,
  alertType: AlertType,
};
export const showAlert = (content: string, alertType: AlertType): ShowAlert => ({
  type: AppActionType.ShowAlert,
  content,
  alertType,
});

export type DismissAlert = Action<AppActionType>;
export const dismissAlert = (): DismissAlert => ({
  type: AppActionType.DismissAlert,
});
