
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppAction, showAlert } from '../app/actions';
import { AlertType } from '../app/state';

import { Note } from './models';
import { NoteState } from './state';

export enum NoteActionType {
  NoOp = 'NOTES_NOOP',
  // TODO: Use ListNotes with actual http call
  ListNotes = 'NOTES_LIST_NOTES',
  OpenNoteEdit = 'NOTES_OPEN_NOTE_EDIT',
  CancelNoteEdit = 'NOTES_CANCEL_NOTE_EDIT',
  SaveNoteEdit = 'NOTES_SAVE_NOTE_EDIT',
  ChangeNoteContent = 'NOTES_CHANGE_NOTE_CONTENT',
}

export type NoteAction = NoOpNotes
  | ListNotes
  | OpenNoteEdit
  | ChangeNoteContent
  ;

// Asynchronous Note Action
export type NoteThunk = ThunkAction<void, NoteState, null, NoteAction | AppAction>;

export class NoOpNotes implements Action<NoteActionType> {
  type = NoteActionType.NoOp;
}

export class ListNotes implements Action<NoteActionType> {
  type = NoteActionType.ListNotes;
  payload: Note[] = [];
}

export type OpenNoteEdit = Action<NoteActionType> & {
  note: Note,
};
export const openNoteEdit = (note: Note): OpenNoteEdit => ({
  type: NoteActionType.OpenNoteEdit,
  // Copy note so we're not sharing ref with list while editing
  note: { ...note },
});

// TODO: Ask for confirmation
export type CancelNoteEdit = Action<NoteActionType>;
export const cancelNoteEdit = (): CancelNoteEdit => ({
  type: NoteActionType.CancelNoteEdit,
})

// TODO: Send to API backend
export type SaveNoteEdit = Action<NoteActionType> & {
  note: Note,
};
export const saveNoteEdit = (note: Note): NoteThunk => {
  console.log("Saving...");
  return dispatch => {
    console.log("Dispatching...");
    dispatch({
      type: NoteActionType.SaveNoteEdit,
      note,
    });

    dispatch(showAlert("Save Success!", AlertType.Success));
  };
};

export type ChangeNoteContent = Action<NoteActionType> & {
  content: string,
}
export const changeNoteContent = (content: string): ChangeNoteContent => ({
  type: NoteActionType.ChangeNoteContent,
  content,
});
