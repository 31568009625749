
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, Button } from 'reactstrap';
import showdown from 'showdown';

import { Note } from '../models';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';

const converter = new showdown.Converter();
converter.setFlavor('github');

type Props = {
  editing?: boolean,
  note: Note,

  onEditOpen?: (note: Note) => void,
  onEditCancel?: () => void,
  onEditSave?: (note: Note) => void,
  onContentChange?: (newContent: string) => void,
}

class NoteItem extends Component<Props> {
  render = () => {
    const footer = (
      <small className="text-muted">
        {this.props.note.topic} | {this.props.note.date.toLocaleDateString('en-GB')} at {this.props.note.date.toLocaleTimeString('en-GB')}
      </small>
    );

    return (
      <Card className="mb-3">

        {!this.props.editing &&
          <CardBody>
            <CardText dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.note.content) }} />
            <CardText>
              {footer}
              <Button
                color="link"
                onClick={() => this.props.onEditOpen && this.props.onEditOpen(this.props.note)}>Edit</Button>
            </CardText>
          </CardBody>}


        {this.props.editing &&
          <CardBody>
            <textarea
              className="form-control"
              rows={10}
              value={this.props.note.content}
              onChange={(ev) => this.props.onContentChange && this.props.onContentChange(ev.target.value)}></textarea>
            <CardText>
              {footer}
            </CardText>
            <ButtonGroup>
              <Button color="primary" onClick={() => this.props.onEditSave && this.props.onEditSave(this.props.note)}>Save</Button>
              {/* TODO: Save & Close */}
              {/* <Button color="secondary">Save &amp; Close</Button> */}
              <Button color="danger" onClick={() => this.props.onEditCancel && this.props.onEditCancel()}>Cancel</Button>
            </ButtonGroup>
          </CardBody>}
      </Card>
    )
  };
}

export default connect()(NoteItem);
